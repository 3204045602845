<template>
  <div class="device-order-manage">
    <app-query-bar
      :queryConfig="queryConfig"
      v-model="queryData"
      @onSearch="fetchDeviceOrders" />
    <app-export-bar
      @onExport="onExport"
      @onExportAll="onExportAll" />
    <app-data-table
      :columns="DEVICE_ORDER_COLUMNS"
      :data="deviceOrders"
      v-model="queryData"
      @onPageConfigChange="fetchDeviceOrders">
      <template
        slot-scope="{ row }"
        slot="buyerGroupType">
        {{ BUYER_GROUP_TYPE_TEXT[row.buyer_group_type] }}
      </template>
      <template
        slot-scope="{ row }"
        slot="status">
        {{ STATUS_TEXT[row.status] }}
      </template>
    </app-data-table>
  </div>
</template>

<script>
import {
  DEVICE_ORDER_COLUMNS,
  BUYER_GROUP_TYPE,
  BUYER_GROUP_TYPE_TEXT,
  BUYER_GROUP_TYPE_OPTIONS,
  STATUS_TEXT,
} from './constants';
import DeviceOrderManageApi from './device-order-manage.api';
import DataTransferUtil from '@/utils/data-transfer.util';

export default {
  name: 'device-order-manage',
  data() {
    return {
      DEVICE_ORDER_COLUMNS,
      BUYER_GROUP_TYPE_TEXT,
      STATUS_TEXT,
      queryConfig: [
        {
          fieldName: 'buyerGroupType',
          name: '买方类型',
          type: 'select',
          options: BUYER_GROUP_TYPE_OPTIONS,
        },
        {
          fieldName: 'buyerName',
          name: '买方',
        },
        {
          fieldName: 'mobile',
          name: '手机号',
        },
        {
          fieldName: 'produceNo',
          name: '生产编号',
        },
        {
          fieldName: 'storeName',
          name: '门店名称',
        },
      ],
      queryData: {
        page: 1,
        limit: 10,
        count: 0,
        buyerGroupType: BUYER_GROUP_TYPE.ALL,
        buyerName: '',
        mobile: '',
        produceNo: '',
        storeName: '',
      },
      deviceOrders: [],
    };
  },
  created() {
    this.fetchDeviceOrders();
  },
  methods: {
    async onExport() {
      const {
        page,
        limit,
        buyerGroupType,
        buyerName,
        mobile,
        produceNo,
        storeName,
      } = this.queryData;
      const key = await DeviceOrderManageApi.export({
        page,
        limit,
        buyer_group_type: buyerGroupType,
        mobile,
        buyer_name: buyerName,
        produce_no: produceNo,
        store_name: storeName,
      });
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
    async  onExportAll() {
      const key = await DeviceOrderManageApi.export();
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
    async fetchDeviceOrders() {
      const {
        page, limit, buyerGroupType, buyerName, mobile, produceNo, storeName,
      } = this.queryData;
      const {
        count = 0,
        results = [],
      } = await DeviceOrderManageApi.getDeviceOrders(
        page,
        limit,
        buyerGroupType,
        buyerName,
        mobile,
        produceNo,
        storeName,
      );
      this.deviceOrders = results;
      this.$set(this.queryData, 'count', count);
    },
  },
};
</script>
