import axios from '@/plugins/axios';
import { BUYER_GROUP_TYPE } from '@/pages/order/device-order-manage/constants';

class DeviceOrderManageApi {
  static getDeviceOrders(
    page = 1,
    limit = 10,
    buyerGroupType = BUYER_GROUP_TYPE.ALL,
    buyerName = '',
    mobile = '',
    produceNo = '',
    storeName = '',
  ) {
    return axios({
      url: '/api/order/product/page',
      data: {
        page,
        limit,
        buyer_group_type: buyerGroupType,
        mobile,
        buyer_name: buyerName,
        produce_no: produceNo,
        store_name: storeName,
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询设备订单列表',
        },
      },
    });
  }

  static export(data) {
    return axios({
      url: '/api/order/product/export',
      method: 'post',
      data,
      headers: {
        meta: {
          skipSuccess: true,
          desc: '导出',
        },
      },
    });
  }
}

export default DeviceOrderManageApi;
