import DataTransferUtil from '@/utils/data-transfer.util';

export const DEVICE_ORDER_COLUMNS = [
  {
    title: '订单号',
    key: 'order_no',
    width: 170,
    fixed: 'left',
  },
  // {
  //   title: '设备编号',
  //   key: 'product_no',
  //   width: 200,
  // },
  {
    title: '生产编号',
    key: 'produce_no',
    width: 200,
  },
  {
    title: '买方',
    key: 'buyer_name',
    width: 150,
  },
  {
    title: '买方类型',
    key: 'buyer_group_type',
    slot: 'buyerGroupType',
    width: 100,
  },
  {
    title: '手机号',
    key: 'buyer_mobile',
    width: 140,
  },
  {
    title: '订单状态',
    key: 'status',
    slot: 'status',
    width: 120,
  },
  {
    title: '门店名称',
    key: 'store_name',
    width: 120,
  },
  {
    title: '创建时间',
    key: 'create_time',
    minWidth: 180,
  },
];

export const BUYER_GROUP_TYPE = {
  STORE: 'store',
  CONSUMER: 'customer',
  ALL: 'all',
};

export const BASIC_BUYER_GROUP_TYPE_TEXT = {
  [BUYER_GROUP_TYPE.STORE]: '门店',
  [BUYER_GROUP_TYPE.CONSUMER]: '消费者',
};

export const BUYER_GROUP_TYPE_TEXT = {
  ...BASIC_BUYER_GROUP_TYPE_TEXT,
  [BUYER_GROUP_TYPE.ALL]: '全部',
};

export const BUYER_GROUP_TYPE_OPTIONS = DataTransferUtil
  .enumTransfer2Options(BUYER_GROUP_TYPE_TEXT);

export const STATUS = {
  RETURN: 0,
  RECEIVE: 1,
};

export const STATUS_TEXT = {
  [STATUS.RETURN]: '已退货',
  [STATUS.RECEIVE]: '已收货',
};
